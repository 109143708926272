import React, { useLayoutEffect, useRef } from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useGSAP } from '@gsap/react';

gsap.registerPlugin(useGSAP, ScrollTrigger);

export default function SectionBox() {
  const main = useRef();

  const data_card = [
    {percent: '72.2%', title: 'Commercial Radio', color: '#11213f'},
    {percent: '10.1%', title: 'Free Music Streaming', color: '#e81f76'},
    {percent: '8.9%', title: 'Free Podcasts', color: '#13adf0'},
    {percent: '4.8%', title: 'Online Music Videos', color: '#9164cd'},
    {percent: '2.1%', title: 'Tv Music Channels', color: '#9a154d'},

  ]

  useGSAP(
    () => {
      const boxes = gsap.utils.toArray('.box');
      boxes.forEach((box) => {
        gsap.to(box, {
          x: 200,
          y: 80,
          scrollTrigger: {
            trigger: box,
            start: 'bottom bottom',
            end: 'top 60%',
            scrub: true,
            // markers: true,
          },
        });
      });
      
    },
    { scope: main }
  );

return (
    <section>
            
        <div className="section flex-center column" ref={main}>
            <div class="bg" style={{backgroundImage: 'url(static/section3.jpg)'}}></div>
            {
                data_card.map((card, index) => (
                    <div className="box card w-200 h-100" key={index} style={{backgroundColor: card.color}}>
                        <span style={{fontSize:"2rem"}}>{card.percent}</span>
                        <span>{card.title}</span> 
                    </div>
                ))

            }
           
        </div>
    </section>
);
}
