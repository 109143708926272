import React, { useLayoutEffect, useRef } from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useGSAP } from '@gsap/react';
import SectionThree from './components/SectionThree';
import SectionTitle from './components/SectionTitle';
import SectionTwo from './components/SectionTwo'; 
import SectionFour from './components/SectionFour';
import SectionFive from './components/SectionFive';
import SectionSix from './components/SectionSix';
import SectionSeven from './components/SectionSeven';
import SectionEight from './components/SectionEight';
import SectionNine from './components/SectionNine';

gsap.registerPlugin(useGSAP, ScrollTrigger);

export default function Scroll() {
  let getRatio = el => window.innerHeight / (window.innerHeight + el.offsetHeight);
  const main = useRef();
  useGSAP(
    () => {
      const sections = gsap.utils.toArray('sectionp');
      sections.forEach((section,i) => {
        section.bg = section.querySelector(".bg"); 

        // Give the backgrounds some random images
        //section.bg.style.backgroundImage = `url(https://picsum.photos/1600/800?random=${i})`;
        gsap.fromTo(section.bg, {
          backgroundPosition: () => i ? `50% ${-window.innerHeight * getRatio(section)}px` : "50% 0px"
        }, {
          backgroundPosition: () => `50% ${window.innerHeight * (1 - getRatio(section))}px`,
          ease: "none",
          scrollTrigger: {
            trigger: section,
            start: () => i ? "top center" : "top top", 
            end: "bottom top",
            scrub: true,
            invalidateOnRefresh: true // to make it responsive
          }
        });
      });
      
    },
    { scope: main }
  );

  return (
    <div ref={main}>
      <SectionTitle/>
      <SectionTwo />
      <SectionThree/>
      <SectionFour/>
      <SectionFive/>
      <SectionSix/>
      <SectionSeven/>
      <SectionEight/>
      <SectionNine/>
      

      <section className="section flex-center column">
        <div class="bg"></div>
        <h2>Is coming</h2></section>
      <SectionThree/>
    </div>
  );
}
