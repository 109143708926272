import React, { useLayoutEffect, useRef } from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useGSAP } from '@gsap/react';
import SplitType from "split-type";
import styles from "./style.module.css";

gsap.registerPlugin(useGSAP, ScrollTrigger);




export default function SectionBox() {
  const main = useRef();
  const textContainerRef = useRef();
  

 
  let masks;
  useGSAP(() => {
    masks = [];
    const split = new SplitType(".paragraph", {
        lineClass: styles.line,
        types: ["lines"]
      });
    split.lines.forEach((target) => {
        console.log(target)
        let mask = document.createElement("span");
        mask.className = "mask";
        target.append(mask);
        masks.push(mask);
        gsap.to(mask, {
        scaleX: 0,
        transformOrigin: "top center",
        ease: "none",
        scrollTrigger: {
            trigger: target,
            markers: {
            startColor: "white",
            endColor: "#42a6e0",
            fontSize: "14px",
            indent: 10
            },
            scrub: true,
            start: "top 100%",
            end: "bottom center"
        }
        });
  })}, { scope: main });

  



  return (
    <section>
      
      <div className="section flex-center column sectiontwo" ref={main}>
      <div class="bg" style={{backgroundImage: 'url(static/section2.jpg)'}}></div>
        <div class="paragraph" ref={textContainerRef}>
            THERE’S NOTHING MORE POWERFUL THAN THE SPOKEN WORD, AND MAKING SOMEONE FEEL SOMETHING WELL, THAT STARTS WITH BEING HEARD.
        </div>
      </div>
     
    </section>
  );
}
