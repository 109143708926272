import React, { useLayoutEffect, useRef } from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useGSAP } from '@gsap/react';

gsap.registerPlugin(useGSAP, ScrollTrigger);

const RightArrowComponent = (props) => (
    <div class="arrow">
    <svg  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 800 800" {...props}>
    <path
      fill="none"
      stroke="hsl(227, 71%, 57%)"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={10}
      markerEnd="url(#a)"
      d="M250 250q200 100 300 300"
    />
    <defs>
      <marker
        id="a"
        markerHeight={5}
        markerWidth={5}
        orient="auto"
        refX={2.5}
        refY={2.5}
        viewBox="0 0 5 5"
      >
        <path fill="hsl(227, 71%, 57%)" d="m0 5 1.667-2.5L0 0l5 2.5z" />
      </marker>
    </defs>
  </svg>
  </div>
  )

export default function SectionBox() {
  const main = useRef();

  useGSAP(
    () => {
        const boxes = gsap.utils.toArray('.five-card');
        boxes.forEach((box) => {
          gsap.timeline({
              scrollTrigger: {
                 trigger: box,
                 scrub: 0.3,
                 start: 'top center',
                 end: 'bottom bottom',
                 markers: true,
              }
           })
           .to(box, {
              opacity: 1,
              duration: 1,
              ease: "none",
              stagger: 1
           });
        });
        const arrows = gsap.utils.toArray('.arrow');
        arrows.forEach((box) => {
            gsap.timeline({
                scrollTrigger: {
                   trigger: box,
                   scrub: 0.3,
                   start: 'top 55%',
                   end: 'bottom center',
                   markers: true,
                }
             })
             .to(box, {
                opacity: 1,
                duration: 1,
                ease: "none",
                stagger: 1
             });
          });
      
    },
    { scope: main }
  );

return (
    <section>
        
        <div className="section flex-center column" ref={main}>
        <div class="bg" style={{backgroundColor:'white'}}></div>
        <h1 className="funnel-header">LOWER FUNNEL KEY TAKEAWAYS</h1>
       
        <div className="key-points">
                <div class="key-row">
                    <div class="five-card">
                        <div class="card-container">
                        <strong>Radio stands as a Reach Powerhouse:</strong> Achieving an impressive 95% coverage of the Australian population.
                        </div>
                        
                    </div>
                    <RightArrowComponent style={{ height: 300}} />
                </div>
                <div class="five-card" style={{alignSelf: 'flex-end'}}>
                    <div class="card-container">
                    <strong>Radio integrates seamlessly:</strong> It's the ultimate sidekick, working well with digital audio and other media channels.
                    </div>
                </div>
                <div class="key-row">
                <div class="five-card">
                    <div class="card-container">
                    <strong>Brand-building campaigns:</strong> Prioritize crafting compelling narratives that evoke emotional connections. These stories have a lasting impact on brand perception and the customer journey.
                    </div>
                </div>
                    <RightArrowComponent style={{ height: 300, transform: 'rotate(90deg)'}} />  
                </div>
                
                
                
            
        </div>
       
        </div>
     
    </section>
);
}
