import React, { useLayoutEffect, useRef } from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useGSAP } from '@gsap/react';

gsap.registerPlugin(useGSAP, ScrollTrigger);

export default function SectionBox() {
  const main = useRef();

  useGSAP(
    () => {
      gsap.to(".title", {
        scrollTrigger: {
          trigger: main.current,
          start: 'top top',
          end: 'bottom center',
          scrub: true,
          markers: true,
        },
        scale: 20
      });
      
    },
    { scope: main }
  );

  return (
    <section>
      
      <div className="section flex-center column" ref={main}>
      <div class="bg" style={{backgroundImage: 'url(static/section_title_1920x1080.jpg)'}}></div>
         <h2 class="title">Audio Edge</h2>
      </div>
     
    </section>
  );
}
