import React, { useLayoutEffect, useRef } from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useGSAP } from '@gsap/react';

gsap.registerPlugin(useGSAP, ScrollTrigger);


const NeonComponent = (props) => (
    
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 800 800" {...props}>
    <g fill="none">
      <path
        stroke="#ff3895"
        strokeWidth={4}
        d="M120.239 639.052 30.44 572.447l-17.365 98.48z"
        opacity={0.1}
      />
      <path
        stroke="#f645a1"
        strokeWidth={5}
        d="M190.514 654.348 75.593 592.646 72.2 709.264z"
        opacity={0.175}
      />
      <path
        stroke="#ed50ab"
        strokeWidth={6}
        d="m258.625 654.802-140.172-50.737 15.48 132.432z"
        opacity={0.25}
      />
      <path
        stroke="#e35ab5"
        strokeWidth={7}
        d="m321.83 641.46-164.3-33.622 38.823 144.889z"
        opacity={0.325}
      />
      <path
        stroke="#d962bf"
        strokeWidth={8}
        d="M377.66 615.833 191.618 605.33l66.013 153.036z"
        opacity={0.4}
      />
      <path
        stroke="#cf6ac8"
        strokeWidth={9}
        d="m423.988 579.85-204.16 18.227 96.245 156.038z"
        opacity={0.475}
      />
      <path
        stroke="#c472d1"
        strokeWidth={10}
        d="m459.102 535.775-217.488 51.953 128.558 153.21z"
        opacity={0.55}
      />
      <path
        stroke="#b879d9"
        strokeWidth={11}
        d="m481.75 486.138-224.967 89.836L418.64 720.013z"
        opacity={0.625}
      />
      <path
        stroke="#ab7fe1"
        strokeWidth={12}
        d="M491.182 433.64 265.49 564.476l194.947 128.219z"
        opacity={0.7}
      />
      <path
        stroke="#9e86e9"
        strokeWidth={13}
        d="m487.17 381.065-218.944 173.75L494.803 660.47z"
        opacity={0.775}
      />
      <path
        stroke="#8f8cf1"
        strokeWidth={14}
        d="M470.01 331.188 265.797 548.412l255.464 76.48z"
        opacity={0.85}
      />
      <path
        stroke="#7e91f8"
        strokeWidth={15}
        d="m440.519 286.676-181.235 259.81 280.342 41.064z"
        opacity={0.925}
      />
      <path stroke="#6b97ff" strokeWidth={16} d="M400 250 250 550h300z" />
    </g>
  </svg>
)



export default function SectionBox() {
  const main = useRef();

  useGSAP(
    () => {
      gsap.to(".title", {
        scrollTrigger: {
          trigger: main.current,
          start: 'top top',
          end: 'bottom center',
          scrub: true,
          markers: true,
        },
        scale: 20
      });
      const boxes = gsap.utils.toArray('path');
        boxes.forEach((box) => {
          gsap.timeline({
              scrollTrigger: {
                 trigger: box,
                 scrub: 0.3,
                 start: 'top center',
                 end: 'bottom bottom',
                 markers: true,
              }
           })
           .to(box, {
              opacity: 0,
              duration: 1,
              ease: "none",
              stagger: 1
           });
        });
      
    },
    { scope: main }
  );

  return (
    <section>
      
      <div className="section flex-center column" ref={main}>
      <div class="bg" style={{backgroundImage: 'url(static/section6.jpg)'}}></div>
        <NeonComponent style={{height: '1000px', position: 'absolute'}} />
         <h2 class="title">LOWER FUNNEL</h2>
      </div>
     
    </section>
  );
}
