import React, { useLayoutEffect, useRef } from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useGSAP } from '@gsap/react';

gsap.registerPlugin(useGSAP, ScrollTrigger);






export default function SectionBox() {
  const main = useRef();

  
  return (
    <section>
      
      <div className="section flex-center column" ref={main}>
      <div class="bg" style={{backgroundImage: 'url(static/section9.jpg)'}}></div>
        

      <div class="chart-container flex-row sm:flex-col md:flex-col lg:flex-row xl:flex-row gap-2">
         <div class="px-4 py-4 w-96 text-center">
          <div class="font-bold text-5xl mb-2">POSITIVE ESOV, USING RADIO</div>
          <div class="font-bold text-8xl mb-2">26%</div>
        </div>

        <div class="px-4 py-4 w-96 text-center">
          <div class="font-bold text-5xl mb-2">POSITIVE ESOV, NOT USING RADIO</div>
          <div class="font-bold text-8xl mb-2">8%</div>
        </div>
          
      </div>
      <div class="max-w-sm rounded overflow-hidden shadow-lg">
  
     
      
    </div>
    <h1 class="title-nine" style={{position:"absolute",bottom:'50px'}} >CUSTOMER RETENTION</h1>
      </div>
      
    
    </section>
  );
}
