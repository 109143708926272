import React, { useLayoutEffect, useRef } from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useGSAP } from '@gsap/react';
import './css/FunnelComponent.css';

gsap.registerPlugin(useGSAP, ScrollTrigger);


const data = {
    Awareness: {
      Radio: "58%",
      TV: "62%",
      TikTok: "39%",
      Facebook: "52%",
      Instagram: "46%",
      BVOD: "40%",
      OnlineVideo: "47%",
      OOH: "50%",
    },
    Consideration: {
      Radio: "48%",
      TV: "52%",
      TikTok: "36%",
      Facebook: "43%",
      Instagram: "41%",
      BVOD: "34%",
      OnlineVideo: "39%",
      OOH: "40%",
    },
    Conversion: {
      Radio: "54%",
      TV: "58%",
      TikTok: "38%",
      Facebook: "49%",
      Instagram: "44%",
      BVOD: "37%",
      OnlineVideo: "44%",
      OOH: "47%",
    },
    Loyalty: {
      Radio: "42%",
      TV: "47%",
      TikTok: "31%",
      Facebook: "38%",
      Instagram: "36%",
      BVOD: "30%",
      OnlineVideo: "36%",
      OOH: "36%",
    },
  };
  
  const FunnelTable = () => {
    const main = useRef();

 

  useGSAP(
    () => {
      const boxes = gsap.utils.toArray('.table-row');
      boxes.forEach((box) => {
        gsap.timeline({
            scrollTrigger: {
               trigger: box,
               scrub: 0.3,
               start: 'bottom 95%',
               end: 'center 90%',
               markers: true,
            }
         })
         .to(box, {
            opacity: 1,
            duration: 1,
            ease: "none",
            stagger: 1
         });
      });
      
    },
    { scope: main }
  );
    return (
      <div className="funnel-table" ref={main}>
        <div className="table-header">
          <div className="table-cell">RADIO</div>
          <div className="table-cell">TV</div>
          <div className="table-cell">TIK TOK</div>
          <div className="table-cell">FACEBOOK</div>
          <div className="table-cell">INSTAGRAM</div>
          <div className="table-cell">BVOD</div>
          <div className="table-cell">ONLINE VIDEO</div>
          <div className="table-cell">OOH</div>
        </div>
        <div className="table-row awareness">
          {Object.values(data.Awareness).map((value, index) => (
            <div className="table-cell" key={index}>{value}</div>
          ))}
        </div>
        <div className="table-row consideration">
          {Object.values(data.Consideration).map((value, index) => (
            <div className="table-cell" key={index}>{value}</div>
          ))}
        </div>
        <div className="table-row conversion">
          {Object.values(data.Conversion).map((value, index) => (
            <div className="table-cell" key={index}>{value}</div>
          ))}
        </div>
        <div className="table-row loyalty">
          {Object.values(data.Loyalty).map((value, index) => (
            <div className="table-cell" key={index}>{value}</div>
          ))}
        </div>
      </div>
    );
  };

export default function Section() {
  const main = useRef();

 

  useGSAP(
    () => {
      const boxes = gsap.utils.toArray('.funnel-stage');
      boxes.forEach((box) => {
        gsap.timeline({
            scrollTrigger: {
               trigger: box,
               scrub: 0.3,
               start: 'bottom 50%',
               end: 'top 70%',
               markers: true,
               pin: true,
            }
         })
         .to(box, {
            opacity: 1,
            duration: 1,
            ease: "none",
            stagger: 1
         });
      });
      gsap.to("#path", {
        drawSVG: "0%",
        duration: 1,
        scrollTrigger: {
            trigger: "#path",
            scrub: 0.3,
            start: 'top center',
            end: 'top center',
            markers: true
         }
      });
      
    },
    { scope: main }
  );

return (
    <section>
            
        <div className="section flex column" ref={main}>
            
            <div class="bg" style={{backgroundColor: 'white'}}></div>
                <div className="funnel-container">
                    <div className="funnel-stage awareness">
                        <h2>Awareness</h2>
                    </div>
                    <div className="funnel-stage consideration">
                        <h2>Consideration</h2>
                    </div>
                    <div className="funnel-stage conversion">
                        <h2>Conversion</h2>
                    </div>
                    <div className="funnel-stage loyalty">
                        <h2>Loyalty</h2>
                    </div>
                </div>
                <FunnelTable />
              
           
        </div>
    </section>
);
}
