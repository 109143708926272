import React, { useLayoutEffect, useRef } from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useGSAP } from '@gsap/react';

gsap.registerPlugin(useGSAP, ScrollTrigger);


const NeonComponent = (props) => (
    
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 800 800" {...props}>
    <g fill="none">
      <circle
        cx={167}
        cy={684}
        r={231.5}
        stroke="#0d0207"
        strokeWidth={4}
        opacity={0.1}
        transform="rotate(360 400 400)"
      />
      <circle
        cx={186.417}
        cy={660.333}
        r={224.708}
        stroke="#212c4a"
        strokeWidth={5}
        opacity={0.175}
        transform="rotate(330 400 400)"
      />
      <circle
        cx={205.833}
        cy={636.667}
        r={217.917}
        stroke="#2d3e68"
        strokeWidth={6}
        opacity={0.25}
        transform="rotate(300 400 400)"
      />
      <circle
        cx={225.25}
        cy={613}
        r={211.125}
        stroke="#374c80"
        strokeWidth={7}
        opacity={0.325}
        transform="rotate(270 400 400)"
      />
      <circle
        cx={244.667}
        cy={589.333}
        r={204.333}
        stroke="#3f5793"
        strokeWidth={8}
        opacity={0.4}
        transform="rotate(240 400 400)"
      />
      <circle
        cx={264.083}
        cy={565.667}
        r={197.542}
        stroke="#4661a5"
        strokeWidth={9}
        opacity={0.475}
        transform="rotate(210 400 400)"
      />
      <circle
        cx={283.5}
        cy={542}
        r={190.75}
        stroke="#4c6bb4"
        strokeWidth={10}
        opacity={0.55}
        transform="rotate(180 400 400)"
      />
      <circle
        cx={302.917}
        cy={518.333}
        r={183.958}
        stroke="#5273c3"
        strokeWidth={11}
        opacity={0.625}
        transform="rotate(150 400 400)"
      />
      <circle
        cx={322.333}
        cy={494.667}
        r={177.167}
        stroke="#587bd0"
        strokeWidth={12}
        opacity={0.7}
        transform="rotate(120 400 400)"
      />
      <circle
        cx={341.75}
        cy={471}
        r={170.375}
        stroke="#5d83dd"
        strokeWidth={13}
        opacity={0.775}
        transform="rotate(90 400 400)"
      />
      <circle
        cx={361.167}
        cy={447.333}
        r={163.583}
        stroke="#628ae9"
        strokeWidth={14}
        opacity={0.85}
        transform="rotate(60 400 400)"
      />
      <circle
        cx={380.583}
        cy={423.667}
        r={156.792}
        stroke="#6791f4"
        strokeWidth={15}
        opacity={0.925}
        transform="rotate(30 400 400)"
      />
      <circle cx={400} cy={400} r={150} stroke="#6b97ff" strokeWidth={16} />
    </g>
  </svg>
)



export default function SectionBox() {
  const main = useRef();

  useGSAP(
    () => {
        gsap.to(".title-loyal", {
            scrollTrigger: {
              trigger: main.current,
              start: 'top 20%',
              end: 'bottom bottom',
              scrub: true,
              markers: true,
            },
            opacity:1
          });
      
      const boxes = gsap.utils.toArray('circle');
        boxes.forEach((box) => {
          gsap.timeline({
              scrollTrigger: {
                 trigger: box,
                 scrub: 0.3,
                 start: 'top center',
                 end: 'bottom bottom',
                 markers: true,
              }
           })
           .to(box, {
              opacity: 0,
              duration: 1,
              ease: "none",
              stagger: 1
           });
        });
      
    },
    { scope: main }
  );

  return (
    <section>
      
      <div className="section flex-center column" ref={main}>
      <div class="bg" style={{backgroundImage: 'url(static/section8.jpg)'}}></div>
        
         <h2 class="title-loyal" style={{opacity:0, fontSize: '4rem'}}>LOYALTY</h2>
         <NeonComponent style={{height: '1000px', position: 'absolute'}} />
      </div>
     
    </section>
  );
}
